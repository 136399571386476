<template>
  <!--begin::Mixed Widget 18-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.certificate.title') }}
          <div v-if="loaded && cnt > 0" class="font-size-sm text-muted">
            <span v-for="(range, index) in ranges" :key="index">
              {{ index === 0 ? $t('dashboard.certificate.period', { from: range.ts_start, to: range.ts_end })
                : '| ' + range.ts_start + ' - ' + range.ts_end}}
            </span>
          </div>
          <PuSkeleton v-if="!loaded" />
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body Active-->
    <div v-if="cnt > 0 || !loaded" class="card-body pt-0">
      <!--begin::Short Info-->
      <p class="mb-1">{{ $t('dashboard.certificate.used') }}:</p>
      <h2 v-if="loaded" class="text-secondary text-muted font-weight-bolder">
        <font-awesome-icon icon="wallet" class="icon-md mr-1" />
        {{ $t('dashboard.certificate.used_value', {deposit: cnt_used}) }}</h2>
        <PuSkeleton v-else />
      <p class="mt-5 mb-1">{{ $t('dashboard.certificate.left') }}:</p>
      <h2 v-if="loaded" class="font-weight-bolder">
        <font-awesome-icon icon="wallet" class="text-info icon-md mr-1" />
        {{ $t('dashboard.certificate.left_value', {deposit: cnt - cnt_used}) }}
      </h2>
      <PuSkeleton v-else />
      <div v-if="loaded" class="progress mt-3" style="height: 5px;">
        <div class="progress-bar bg-info" role="progressbar" :style="'width:'+(100 - (cnt_used / cnt * 100))+'%;'"
             :aria-valuenow="100 - (cnt_used / cnt * 100)" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <PuSkeleton v-else />
      <!--end::Short Info-->
    </div>
    <!--end::Body Active-->
    <!--begin::Body InActive-->
    <div v-else class="card-body d-flex flex-column pt-0">
      <!--begin::Short Info-->
      <p v-html="$t('dashboard.certificate.inactive')"></p>
      <div class="mt-auto">
        <router-link to="/services" class="btn btn-block btn-light-primary">
          {{ $t('dashboard.certificate.buy_certificate') }}
        </router-link>
      </div>
      <!--end::Short Info-->
    </div>
    <!--end::Body InActive-->
  </div>
  <!--end::Mixed Widget 18-->
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment'

export default {
  props: [
    'filterID',
    'title'
  ],
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      packages: 'dashboard/packages',
    }),
  },
  data: () => {
    return {
      ranges: [],
      // ts_start: null,
      // ts_end: null,
      cnt_used: 0,
      cnt: 0,
      loaded: false,
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('dashboard', [
      'PackagesInit'
    ]),
  },
  watch: {
    packages: {
      handler() {
        if (this.packages.length > 0) {
          // this.ts_start = moment.unix(this.packages[0].ts_start).format('DD.MM.YYYY')
          // this.ts_end = moment.unix(this.packages[0].ts_end).format('DD.MM.YYYY')

          for (let i in this.packages) {
            if (this.packages[i].rate.length > 0) {
              for (let r in this.packages[i].rate) {
                if (this.packages[i].rate[r].rate.type == 'certificate') {
                  if(this.filterID && this.filterID != this.packages[i].rate[r].rate.id) continue;
                  let ts_start = moment.unix(this.packages[i].ts_start).format('DD.MM.YYYY');
                  let ts_end = moment.unix(this.packages[i].ts_end).format('DD.MM.YYYY');
                  this.ranges.push({ ts_start, ts_end });
                  this.cnt_used = this.cnt_used + this.packages[i].rate[r].cnt_used || 0
                  this.cnt = this.cnt + this.packages[i].rate[r].cnt || 0
                }
              }
            }
            this.ranges = this.ranges.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.ts_start === value.ts_start && t.ts_end === value.ts_end
                    ))
            )
          }

        }
        this.loaded = true
      },
      deep: true,
    },
  },
}
</script>
