<template>
  <div>
    <div class="row">
      <div class="col-xl-4">
        <div class="row">
          <div v-if="widgets.meetingRoom" class="col-xl-12"><MeetingRoom></MeetingRoom></div>
          <div v-if="widgets.passes" class="col-xl-12"><OrderedPasses></OrderedPasses></div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="row">
          <div v-if="widgets.coinsBalance" class="col-xl-6"><Coins></Coins></div>
          <div class="col-xl-6"><LoyaltyLevel></LoyaltyLevel></div>
          <div v-if="widgets.askAdminQuestion" class="col-xl-6"><AskAdminQuestion></AskAdminQuestion></div>
          <div v-if="widgets.printSheetBalance" class="col-xl-6"><SheetsForPrinting></SheetsForPrinting></div>
          <div v-if="widgets.certificateBalance && coworkingById.id !== 2" class="col-xl-6"><Certificate filterID="56"></Certificate></div>
          <div v-if="widgets.booking" class="col-xl-6"><Bookings></Bookings></div>
          <div v-if="widgets.debt" class="col-xl-6"><Debt></Debt></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="widgets.events" class="col-xl-6">
        <Events></Events>
      </div>
      <div v-if="widgets.offers" class="col-xl-6">
        <Offers></Offers>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import MeetingRoom from './widgets/MeetingRoom'
import OrderedPasses from './widgets/OrderedPasses'
import SheetsForPrinting from './widgets/SheetsForPrinting'
import Coins from './widgets/Coins'
import LoyaltyLevel from './widgets/LoyaltyLevel'
import AskAdminQuestion from './widgets/AskAdminQuestion'
import Certificate from './widgets/Certificate'
import Debt from './widgets/Debt'
import Bookings from './widgets/Bookings'
import Events from './widgets/Events'
import Offers from './widgets/Offers'
export default {
  components: {
    MeetingRoom,
    OrderedPasses,
    SheetsForPrinting,
    AskAdminQuestion,
    Coins,
    LoyaltyLevel,
    Certificate,
    Debt,
    Bookings,
    Events,
    Offers,
  },
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      coworkingById: 'spaces/coworkingById',
      locationsList: 'spaces/list',
      loaded: 'dashboard/loaded',
      packages: 'dashboard/packages',
      orders: 'dashboard/orders',
      bookings: 'dashboard/bookings',
      events: 'dashboard/events',
      offers: 'dashboard/offers',
      integrations: 'main/integrations'
    }),
  },
  data: () => {
    return {
      widgets: {
        meetingRoom: false,
        passes: false,
        printSheetBalance: false,
        askAdminQuestion: false,
        coinsBalance: null,
        certificateBalance: false,
        booking: false,
        debt: false,
        events: false,
        offers: false
      }
    }
  },
  mounted() {
    this.$store.dispatch('locationOrder/GetData');
    this.widgets.meetingRoom = window.__env.widget_meeting_room;
    this.widgets.printSheetBalance = window.__env.widget_sheets_for_printing;
    this.widgets.askAdminQuestion = window.__env.widget_ask_admin_question;
    this.widgets.coinsBalance = window.__env.widget_coins;
    this.widgets.certificateBalance = window.__env.widget_certificate;
    this.widgets.booking = window.__env.widget_booking;
    this.widgets.debt = window.__env.widget_debt;
    this.widgets.events = window.__env.widget_events;
    this.widgets.offers = window.__env.widget_offers;
    this.OrdersInit().then(() => {
      this.widgets.passes = this.integrations && this.integrations.indexOf('integration_otp') > -1;
      this.BookingsInit().then(() => {
        const i = setInterval(() => {
          if(this.locationsList.length) {
            clearInterval(i);
            if (this.locationsList) {
              this.EventsInit(this.locationsList).then(() => {
                this.OffersInit(this.locationsList).then(() => {
                  this.PackagesInit(this.locationsList)
                });
              });
            }
          }
        }, 100);
        if (this.coworkingById && this.widgets.passes) {
          this.PassesInit(this.coworkingById.id).then(() => {
            this.TargetInit(this.coworkingById.id);
          });
        }
      });
    });
  },
  methods: {
    ...mapActions('dashboard', [
      'PackagesInit',
      'OrdersInit',
      'BookingsInit',
      'EventsInit',
      'OffersInit',
      'PassesInit',
      'TargetInit'
    ]),
  },
  watch: {
    packages: {
      handler() {
      },
      deep: true,
    },
  },
}
</script>