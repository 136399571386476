<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.coins.level') }}
          <PuSkeleton v-if="!this.loaded" />
        </div>
      </div>
    </div>
    <div v-if="this.level" class="card-body pt-0">
      <div class="btn btn-block btn-light-warning" style="color:white;font-weight:bold;" :style="{backgroundColor: '#' + this.level.color}" disabled>{{ this.level.name }}</div>
      <div class="font-size-sm text-muted">
        <p></p>
        <p>
          {{ $t('dashboard.coins.available')}}:<br />
          {{ $t('dashboard.coins.percentCoins', {percent: this.level.percent}) }}<br />
          <span v-if="this.level.freeze_period">{{ $t('dashboard.coins.freeze_period', {days: this.level.freeze_period}) }}<br /></span>
          <span v-if="this.level.extend_before">{{ $t('dashboard.coins.extend_before', {days: this.level.extend_before}) }}<br /></span>
          {{ $t('dashboard.coins.partner_discount', {level: this.level.partner_discount}) }}
        </p>
      </div>
    </div>
    <div v-else class="card-body d-flex flex-column pt-0">
      <p v-html="$t('dashboard.coins.connectToLoyalty')"></p>
      <div class="mt-auto"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  async mounted() {
//    await this.$store.dispatch('dashboard/fetchCoins')
    this.loaded = true
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      level: 'dashboard/loyaltyLevel'
    }),
  }
}
</script>