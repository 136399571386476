<template>
  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 my-lg-0">
      <div class="card-title font-weight-bolder text-dark font-size-h4 my-0">
        {{ $t('dashboard.offers.title') }}
        <PuSkeleton v-if="!loaded" />
      </div>
    </div>

    <div class="card-body d-flex flex-column flex-grow pt-0">
      <div v-if="offers_list.length > 0 && loaded">
        <div class="d-flex align-items-center mb-5" v-for="item in offers_list.slice(0, 3)" :key="item.id">
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-5">
            <div class="symbol-label" :style="{'background-image': 'url(' + item.imageUrl + ')'}"></div>
          </div>
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
            <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-truncate w-90">
              {{ item.name }}
            </a>
            <span class="text-dark-50 font-weight-normal font-size-sm text-ellips">
              {{ item.partner_name }}
            </span>
          </div>
          <router-link :to="/offers/ + item.id" class="d-flex btn btn-light-success btn-md-1">
            <font-awesome-icon icon="chevron-right" class="icon-md"/>
          </router-link>
        </div>
      </div>

      <div v-else class="d-flex flex-grow-1 pb-5">
        <p class="text-dark-50" v-html="$t('dashboard.offers.inactive')"></p>
      </div>

      <div>
        <router-link to="/offers" class="btn btn-light-primary w-50 mt-5">
          {{ $t('dashboard.offers.all_offers') }}
        </router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex';

export default {
   computed: {
    ...mapState({
      // offers: state => state.dashboard.offers,
    }),
    ...mapGetters({
      loaded: 'dashboard/loaded',
      offers: 'dashboard/offers',
    }),
  },
  data: () => {
    return {
      offers_list: [],
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'OffersInit'
    ]),
  },
  watch: {
    offers: {
      handler() {
        if (this.offers.length > 0) {
          for (let i in this.offers) {
            this.offers_list.push({
              id: this.offers[i].id,
              name: this.offers[i].name,
              imageUrl: this.offers[i].imageUrl == null ? require('@/assets/no_image.png') : this.offers[i].imageUrl,
              partner_name: this.offers[i].partner_name,
              description: this.offers[i].description,
              ts_created: this.offers[i].ts_created,
            })
          }
          this.offers_list.sort((a, b) => b.ts_created - a.ts_created);
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .w-90 {
    max-width: 90%;
  }
  .text-ellips {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    /* text-align: justify; */
  }
</style>