<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.ask_admin_question.title') }}
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column pt-0">
      <template v-if="loaded">
        <p>{{ $t('dashboard.ask_admin_question.disc') }}</p>
        <div class="mt-auto">
          <span @click="showDialogRequestToSupportModal" class="btn btn-block btn-light-primary">
            {{ $t('dashboard.ask_admin_question.submit') }}
          </span>
        </div>
      </template>
      <template v-else>
        <PuSkeleton :count="2"></PuSkeleton>
      </template>
    </div>
    <!--end::Body-->
    <!--begin::Ask Admin Modal-->
    <AskAdminQuestionModal :showAskAdminModal="showAskAdminModal"
                           @updateDialogRequestToSupportModal="updateDialogRequestToSupportModal" />
    <!--end::Ask Admin Modal-->
  </div>
</template>

<script>
import AskAdminQuestionModal from "../../../../components/core/AskAdminQuestionModal";

export default {
  name: "AskAdminQuestion",
  components: {
    AskAdminQuestionModal
  },
  data: () => {
    return {
      loaded: true,
      showAskAdminModal: false
    }
  },
  methods: {
    showDialogRequestToSupportModal() {
      this.showAskAdminModal = true
    },
    updateDialogRequestToSupportModal(value) {
      this.showAskAdminModal = value;
    },
  }
}
</script>

<style scoped>

</style>
